var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "download_app_page" }, [
      _c(
        "a",
        {
          staticClass: "btn ios",
          attrs: { href: "./static/app/ios/manifest.plist" },
        },
        [_vm._v("安装IOS")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }